.leftContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 10px;
  .heading {
    span {
      font-size: 7vmin;
      background-color: #2f4a60;
      color: white;
      padding: 0px 10px;
      border-radius: 8px;
    }
  }
  .subHeading {
    font-size: 2.6vmin;
  }
}

.heading {
  margin: 3% 0px;
  font-size: 6vmin;
  font-weight: 700;
}

.subHeading {
  width: 80%;
}

.buttonsContainer {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.topContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 110px);
  padding: 0px 5%;
}

.rightContainer {
  // width: auto;
  // height: 100%;
  width: 50%;
}

.rightContainer img {
  width: 100%;
  object-fit: cover;
}

.jobOpportunitesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.jobOpportunites {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;
}

.subTitle {
  font-weight: 500;
  font-size: 2.5vmin;
  text-align: center;
}

.placementsPage {
  .formContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 5vw;
    padding: 40px;
    border-radius: 8px;
    background-color: #e3f0fc;
    margin-bottom: 60px;
    column-gap: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    // width: 60%;
    row-gap: 10px;
    .heading {
      font-size: 3.8vmin;
    }
  }
  .form {
    border-radius: 8px;
    background-color: white;
    width: 400px;
    padding: 20px 30px;
  }
  .placements.heading {
    margin: 0px 5vw;
    font-size: 4.5vmin;
    margin-bottom: 20px;
    color: #518c49;
    text-align: center;
  }
}

.companiesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 900px) {
  .placementsPage {
    .formContainer {
      flex-direction: column;
      align-items: center;
      margin: 0px 5vw;
      padding: 5%;
      border-radius: 8px;
      background-color: #e3f0fc;
      margin-bottom: 60px;
      row-gap: 30px;
      form {
        display: flex;
        justify-content: center;
        width: 85vw;
      }
    }
    .content {
      width: 100%;
    }
    .form {
      width: 90%;
      padding: 5%;
    }
  }
}

@media screen and (max-width: 600px) {
  .topContainer {
    flex-direction: column;
    // justify-content: space-around;
  }
  .leftContainer {
    width: 100%;
  }
  .rightContainer {
    width: 70%;
  }
}
