.eLibraryPage {
  display: flex;
  padding: 0px 60px;
  .eLibraryPageImg {
    width: 60%;
    object-fit: cover;
  }
}

.folders {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 20px;
}

@media screen and (max-width: 900px) {
  .eLibraryPage {
    margin-top: 5%;
    flex-direction: column-reverse;
    align-items: center;
    .eLibraryPageImg {
      width: 80vw;
    }
  }
}
