.careerPage {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  img {
    width: 50%;
    object-fit: cover;
  }
  .form {
    width: 400px;
    padding: 20px 30px;
  }
}

.careerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: "50%";
  .form {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 900px) {
  .careerPage {
    margin-top: 5%;
    flex-direction: column-reverse;
    img {
      width: 80vw;
    }
    .form {
      width: 80vw;
      padding: 5%;
    }
  }
}
