@import "colors";

.navbarContainer {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 999;
}

.inquiryBanner {
  display: flex;
  height: 50px;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 5%;
  font-weight: 500;
  color: white;
  background-color: $primary;
}

.navContainer {
  width: 100%;
  height: 60px;
  background-color: $white;
  position: relative;
  z-index: 10;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
  nav {
    height: 100%;
  }
}
.navContent {
  display: grid;
  grid-template-columns: 15% 70% auto;
  height: 100%;
  align-items: center;
  padding: 0 5%;
}

.navLogo {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: $primary;
  text-decoration: none;
}
.navLinks {
  margin: 0;
  display: flex;
  align-items: center;
  height: 100%;
}
.navItem {
  height: 100%;
  text-decoration: none;
  padding: 0px 20px;
  &:hover {
    color: $primary !important;
  }
}
.navLink {
  height: calc(100% - 2px);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: $darkGray;
  transition: all 0.3s ease 0s;
}
.active {
  color: $primary;
  border-bottom: 2px solid $black;
}
.authBtns {
  display: flex;
  align-items: center;
}
.menu-icon {
  display: none;
}
.close-icon {
  display: none;
}
@media only screen and (max-width: 1080px) {
  .navContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navContainer {
    nav {
      display: none;
    }
  }
  .authBtns {
    display: none;
  }
  .navLinks {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .navItem {
    display: inline-block;
    padding: 5px 0px;
  }
  .navContainerExpanded {
    nav {
      width: 100%;
      margin-top: 17px;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
    }
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 17px 5%;
    height: auto;
    z-index: 10;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
    .authBtns {
      display: flex;
      margin-top: 10px;
    }
    .menu-icon {
      display: none;
      cursor: none;
    }
  }
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .close-icon {
    display: block;
    cursor: pointer;
  }
}
