@import "colors";

.textFieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: $lightGray;
  border-radius: 12px;
  height: 50px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputLabel {
  font-size: 0.7rem;
  font-weight: 600;
  color: $darkGray;
}
.inputLabelError {
  font-size: 0.7rem;
  font-weight: 600;
  color: $error;
}
input {
  border: none;
  background-color: transparent;
  outline: none;
  line-height: 40px;
  width: 95%;
  font-size: 0.9rem;
  &:-webkit-autofill {
    background-clip: text;
    -webkit-background-clip: text;
  }
}
.helperText {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 0.7rem;
  font-weight: 500;
  color: $error;
}
