@import "colors";

.featureCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;
  padding: 20px;
  background-color: $white;
  border-radius: 15px;
  color: $black;
  width: 280px;
  min-height: 200px;
  // cursor: pointer;

  .title {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .subHeading {
    font-size: 0.8rem;
    opacity: 0.9;
  }
}

@media screen and (max-width: 400px) {
  .featureCard {
    width: 240px;
  }
}
