.featuresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}

.featuresTitle {
  text-align: center;
  font-size: 5vmin;
  font-weight: 700;
}

.features {
  display: flex;
  column-gap: 40px;
  row-gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}
