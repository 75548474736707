$white: #ffffff;
$black: #000000;
$primary: #142850;
$secondary: #27496d;
$lightBlue: #1964f1;
$backgroundColor: #fbfffe;
$lightPink: #fcd1d1;
$borderDefault: #eaeaea;
$borderDark: #9d9d9d;
$success: #5daa60;
$error: #dd3e31;
$lightGray: #f2f6fb;
$darkGray: #525552;

:export {
  white: $white;
  black: $black;
  primary: $primary;
  secondary: $secondary;
  lightBlue: $lightBlue;
  backgroundColor: $backgroundColor;
  lightPink: $lightPink;
  borderDefault: $borderDefault;
  borderDark: $borderDark;
  success: $success;
  error: $error;
  lightGray: $lightGray;
  darkGray: $darkGray;
}
