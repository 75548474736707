.techTopCon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  background-color: #113654;

  .innerCon {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 50%;

    .title {
      font-size: 2rem;
      color: #e89319;
      font-weight: 500;
      text-align: center;
    }

    .subTitle {
      color: white;
      text-align: center;
      font-size: 1rem;
    }
  }

  .animation-wrapper {
    width: 80%;
    padding-bottom: 40%;
  }

  .stagger-visualizer {
    position: absolute;
    width: 1100px;
    height: 550px;
    transform-origin: left top;
  }

  .stagger-visualizer .dots-wrapper {
    transform: translateZ(0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .stagger-visualizer .dot {
    position: relative;
    z-index: 1;
    width: 23px;
    height: 23px;
    margin: 16px;
    background-color: currentColor;
    border-radius: 50%;
  }

  @media (min-width: 740px) {
    .stagger-visualizer .dot {
      background-color: transparent;
      background-image: linear-gradient(180deg, #ffffff 8%, #d3cdc6 100%);
    }
  }

  .stagger-visualizer .cursor {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 37px;
    height: 37px;
    margin: 9px;
    background-color: currentColor;
    border-radius: 50%;
  }
}

.infoCon {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  .info {
    width: 50%;
    text-align: center;
    line-height: 1.5rem;
  }
}

.featuresContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 5%;
  margin-top: 40px;
  padding: 40px 0px;
  row-gap: 20px;

  .heading {
    font-size: 2rem;
    margin: 0;
  }

  .featuresCon {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .subHeading {
    text-align: center;
  }
}

.mlLifeCycleCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 10%;
  padding: 40px 0px;

  .mlLifeCycle {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    .leftCon {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 45%;
      .heading {
        font-size: 2rem;
        margin: 0;
      }
    }

    .rightCon {
      width: 45%;
      img {
        width: 100%;
      }
    }
  }
}

.useCasesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 10%;
  padding: 40px 0px;

  .heading {
    font-size: 2rem;
    margin: 0;
  }

  .useCases {
    display: flex;
    justify-content: space-around;
    gap: 20px;

    .useCasesInner {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }
}

.whyWorkWithUs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 0 10%;
  padding: 40px 0px;

  .leftCon {
    width: 45%;
    img {
      width: 100%;
    }
  }

  .rightCon {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 45%;
    .heading {
      font-size: 2rem;
      margin: 0;
    }
  }
}

.whyWorkWithUsFeatures {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactUs {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

.contactUsForm {
  width: 50%;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 20px;
}
