@import "colors";

.aboutPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  padding: 40px 5%;
}
.aboutLeftContainer {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 50vw;
  .title {
    font-size: 5vmin;
    font-weight: 700;
    // color: $secondary;
  }
  .p1 {
    // line-height: 1rem;
    font-size: 2.5vmin;
    font-weight: 500;
  }
  .p2 {
    line-height: 1.5rem;
    font-size: 0.9rem;
  }
}
.aboutRightContainer {
  display: flex;
  width: 50vw;
  // column-gap: 40px;
  // .col1 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-around;
  //   row-gap: 7vh;
  // }
  // .col2 {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }
  img {
    // height: 250px;
    // width: 200px;
    width: 100%;
    object-fit: cover;
    // border-radius: 10px;
    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}
// .userDetails {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   text-align: center;
//   row-gap: 8px;
//   .userName {
//     margin-top: 5px;
//     font-size: 1rem;
//     font-weight: 500;
//     color: $secondary;
//   }
//   .userRole {
//     font-size: 0.7rem;
//     font-weight: 600;
//   }
// }
@media screen and (max-width: 1024px) {
  .aboutPage {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .aboutLeftContainer {
    width: 85vw;
  }
  .aboutRightContainer {
    width: 80vw;
    .col1 {
      display: flex;
      flex-direction: row;
      column-gap: 40px;
    }
  }
}
@media screen and (max-width: 800px) {
  .aboutPage {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .aboutLeftContainer {
    width: 85vw;
    .p1 {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
    .p2 {
      font-size: 0.8rem;
      line-height: 1.1rem;
    }
  }
  .aboutRightContainer {
    flex-direction: column;
    row-gap: 7vh;
    .col1 {
      display: flex;
      flex-direction: column;
      column-gap: 40px;
    }
    .col2 {
      display: flex;
    }
  }
}
