@import "colors";

.coursePage {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 5% 40px 5%;
  background-image: linear-gradient(90deg, $lightBlue 0%, $lightBlue 100%);
  background-repeat: no-repeat;
  background-size: 100% 50vh;
}
.header {
  font-size: 5vmin;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 30vh;
  color: $white;
  img {
    width: 25vw;
  }
}

.courses {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 60px;
}

@media screen and (max-width: 600px) {
  .header {
    font-size: 1.5rem;
    margin-top: 8%;
    flex-direction: column;
    img {
      width: 60vw;
    }
  }
  .courses {
    row-gap: 30px;
  }
}
