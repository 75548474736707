.landingPage {
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  height: 100vh;
  background-color: #f4f7f7;
}

.logoContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 50vh;
  width: 50vw;
  transform: translate(-50%, -50%);
  background-image: url(../assets/landing_middle.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.label {
  font-size: 3vmin;
  font-weight: 600;
  z-index: 100;
}

.techIllustration {
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    cursor: pointer;
    height: 150px;
    width: 250px;
  }

  .label {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
}

.eduIllustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  img {
    cursor: pointer;
    height: 150px;
    width: 250px;
  }

  .label {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
}

.researchIllustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  img {
    cursor: pointer;
    height: 150px;
    width: 250px;
  }

  .label {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    img {
      display: none;
    }
  }
}

// @media screen and (max-width: 400px) {
//   .logoContainer {
//     height: 80vh;
//     width: 80vw;
//     transform: translate(-50%, -50%);
//   }

//   .techIllustration {
//     top: 20%;
//     display: flex;
//     justify-content: center;
//     img {
//       display: none;
//     }
//   }
// }
