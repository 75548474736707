.itemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 40px;
  row-gap: 10px;
  width: 150px;
  img {
    object-fit: cover;
  }
  .name {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  }
}
