@import "colors";
@import url("https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@600&display=swap");

.homePage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
  justify-content: center;
  padding: 0px 5%;
  // background-color: $lightPink;
  border-bottom: 1px solid $borderDefault;
}

.homePageInnerCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeHeader {
  display: flex;
  flex-direction: column;
  row-gap: 2vh;
}

.leftHeader {
  .enter {
    font-size: 8vmin;
    width: 40vw;
    font-family: "Exo 2", sans-serif;
    margin-right: 10px;
  }
  .ai {
    background-color: #21baff;
    padding: 0px 10px;
    padding-bottom: 5px;
    border-radius: 30px;
    font-size: 7vmin;
    width: 40vw;
    font-family: "Exo 2", sans-serif;
    color: white;
  }
  span {
    color: black;
    font-family: "Anek Gujarati", sans-serif;
    font-size: 5vmin;
  }
}

.rightHeader {
  display: flex;
  flex-direction: column;
  width: 30vw;

  .content {
    margin-top: 10px;
    font-size: 1.9vmin;
    color: $darkGray;
  }
}
.buttonsContainer {
  display: flex;
  width: 30vw;
}

.featuresSection,
.testimoniesSection {
  padding: 60px 0px;
}

.batchDetailsSection,
.curriculumSection,
.advantagesSection,
.keyHighlightsSection,
.onlineTrainingBGSection {
  padding: 60px 7% calc(3% + 60px) 7%;
}

.onlineTrainingBGSection {
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .backgroundImage {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }

  .heading {
    display: inline-flex;
    background-color: #e0e4f8;
    padding: 10px 10px;
    margin: 0px;
    justify-content: center;
    color: #505fa8;
    border-radius: 30px 30px 0px 0px;
  }
}

.aboutDataScienceSection {
  padding: 60px 5% calc(3% + 60px) 5%;
  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: center;
      row-gap: 5%;
      color: $darkGray;
      font-size: 3vmin;
      width: 50vw;
      ul {
        padding: 20px;
      }
    }
    .illustration {
      width: 45vw;
      img {
        width: 100%;
      }
    }
  }
}

.batchDetailsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary;
  color: $lightGray;

  .batchDetails {
    display: grid;
    width: 60vw;
    grid-template-columns: 50% 50%;
    border-left: 1px solid $borderDefault;
    border-bottom: 1px solid $borderDefault;
  }
  .batchDetails div {
    border: 1px solid $borderDefault;
    border-left: none;
    border-bottom: none;
    padding: 20px;
    font-size: 1rem;
  }
  .gridHead {
    background-color: $lightBlue;
    color: $white;
    font-weight: bold;
  }
}

.curriculumSection {
  .syllabus {
    display: flex;
    justify-content: center;
    column-gap: 20px;
  }
  .syllabus > div {
    width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}

.advantagesSection {
  // .heading {
  //   font-size: 4vmin;
  // }
  .contentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftContainer {
      display: flex;
      flex-direction: column;
    }
    .content > div {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
    .illustration {
      width: 45vw;
      img {
        width: 100%;
      }
    }
  }
}

.keyHighlightsSection {
  .heading {
    font-size: 4.5vmin;
  }
  .highlightsContainer {
    display: flex;
    column-gap: 30px;
  }
  .container {
    padding: 2%;
    width: fit-content;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 12px;
  }
  .content {
    font-size: 2.5vmin;
    line-height: 4.5vmin;
  }
}

.featuresSection {
  color: $white;
  background-image: linear-gradient(
    90deg,
    rgba(2, 59, 166, 1) 0%,
    rgba(67, 126, 235, 1) 100%
  );
  background-color: #437eeb;
}

.testimoniesSection {
  background-color: #f7f5f4;
  padding-bottom: calc(3% + 60px);
}

.companiesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px 60px 0px;

  .heading {
    font-size: 5vmin;
    font-weight: 700;
  }
}

.introVideo {
  display: flex;
  justify-content: center;
  width: 55vw;
}
.introVideoIframe {
  height: 100%;
  width: 60%;
}

@media screen and (max-width: 850px) {
  .introVideo {
    width: 45vw;
  }
  .keyHighlightsSection {
    .highlightsContainer {
      flex-direction: column;
      row-gap: 30px;
    }
    .container {
      width: 80vw;
    }
  }
  .aboutDataScienceSection {
    .contentContainer {
      flex-direction: column;
      .content {
        width: 95vw;
      }
      .illustration {
        width: 80vw;
      }
    }
  }
  .batchDetailsSection {
    .batchDetails {
      width: 90vw;
    }
  }
  .curriculumSection {
    .syllabus {
      flex-direction: column;
    }
    .syllabus > div {
      width: 85vw;
    }
  }
  .advantagesSection {
    .contentContainer {
      flex-direction: column;
      .illustration {
        width: 90vw;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .introVideo {
    width: 80vw;
  }
  .homePageInnerCon {
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
  }
  .buttonsContainer {
    width: 80%;
  }
  .homeHeader {
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 3vh;
  }
  .leftHeader {
    font-size: 5vh;
    width: 80vw;
    span {
      font-size: 4vh;
    }
  }
  .rightHeader {
    width: 80vw;
    .content {
      margin-top: 10px;
      font-size: 1.8vh;
      color: $darkGray;
    }
  }
}
