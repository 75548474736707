/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Cabin&display=swap");

.App {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  min-height: 100vh;
}

.appInnerCon {
  min-height: calc(100vh - 150px);
}

/* @font-face {
  font-family: "Product Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2)
      format("woff2");
} */

body,
html {
  /* font-family: "Product Sans", sans-serif; */
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: "Lato", sans-serif; */
  font-family: "Cabin", sans-serif;
}
