@import "colors";

.footer {
  height: 40px;
  padding: 0px 5%;
  border-top: 1px solid $borderDefault;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
}

.footerLeftContainer,
.footerRightContainer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  color: $lightBlue;
  font-weight: 700;
}

.footer div div {
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .footer {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 20px;
  }
  .footerLeftContainer {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
  .footerRightContainer {
    padding-bottom: 20px;
  }
}
